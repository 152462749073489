import { DevBoxActionDelayResult as GeneratedDevBoxActionDelayResultBeta } from 'devcenter-internal-beta';
import {
    DevBoxActionDelayResult as GeneratedDevBoxActionDelayResultStable,
    DevBoxActionDelayResultStatus as GeneratedDevBoxActionDelayResultStatusStable,
    DevBoxActionSourceType as GeneratedDevBoxActionSourceTypeStable,
    DevBoxAction as GeneratedDevBoxActionStable,
    DevBoxNextAction as GeneratedDevBoxNextActionStable,
} from 'devcenter-internal-stable';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

export type DevBoxActionContract = GeneratedDevBoxActionStable;
export type DevBoxActionDelayResultContract =
    | GeneratedDevBoxActionDelayResultStable
    | GeneratedDevBoxActionDelayResultBeta;

export type DevBoxActionSourceTypeContract = GeneratedDevBoxActionSourceTypeStable;

export const DevBoxActionSourceTypeContract: UnionMap<DevBoxActionSourceTypeContract> = {
    Pool: 'Pool',
    Schedule: 'Schedule',
};

export type DevBoxNextActionContract = GeneratedDevBoxNextActionStable;

export type DevBoxActionDelayResultStatus = GeneratedDevBoxActionDelayResultStatusStable;
export const DevBoxActionDelayResultStatus: UnionMap<DevBoxActionDelayResultStatus> = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
};

export type DevBoxActionActionType = DevBoxActionContract['actionType'];
export const DevBoxActionActionType: UnionMap<DevBoxActionActionType> = {
    Stop: 'Stop',
};

export type CommonSkipDelayDevBoxActionErrorCode =
    | 'DevBoxActionAlreadyComplete'
    | 'DevBoxActionAlreadySkipped'
    | 'DevBoxActionPreferenceUpdateViolatesThreshold'
    | 'DevBoxActionPreferenceUpdateNotInAllowedTimeWindow';
export const CommonSkipDelayDevBoxActionErrorCode: UnionMap<CommonSkipDelayDevBoxActionErrorCode> = {
    DevBoxActionAlreadyComplete: 'DevBoxActionAlreadyComplete',
    DevBoxActionAlreadySkipped: 'DevBoxActionAlreadySkipped',
    /** This error code occurs when scheduled time is not more than 5 minutes from UTC now (at time of validation) */
    DevBoxActionPreferenceUpdateViolatesThreshold: 'DevBoxActionPreferenceUpdateViolatesThreshold',
    /** This error code occurs when scheduled time of this action is more than 24 hours from now */
    DevBoxActionPreferenceUpdateNotInAllowedTimeWindow: 'DevBoxActionPreferenceUpdateNotInAllowedTimeWindow',
};

export type DelayDevBoxActionErrorCode =
    | 'InvalidDelayUntilTime'
    | 'DelayUntilTimeExceedLimit'
    | CommonSkipDelayDevBoxActionErrorCode;
export const DelayDevBoxActionErrorCode: UnionMap<DelayDevBoxActionErrorCode> = {
    ...CommonSkipDelayDevBoxActionErrorCode,
    /** This error code occurs when requested delay time is before the time of the scheduled action */
    InvalidDelayUntilTime: 'InvalidDelayUntilTime',
    /** This error code occurs when requested delay time is >8 hours after the originally scheduled time */
    DelayUntilTimeExceedLimit: 'DelayUntilTimeExceedLimit',
};

export type SkipDevBoxActionErrorCode = CommonSkipDelayDevBoxActionErrorCode;
export const SkipDevBoxActionErrorCode: UnionMap<SkipDevBoxActionErrorCode> = CommonSkipDelayDevBoxActionErrorCode;
