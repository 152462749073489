import { CreateDevBoxErrorCode } from '../constants/dev-box';
import { CreateEnvironmentErrorCode } from '../constants/environment';
import {
    CloudErrorBodyContract,
    CloudErrorContract,
    OperationStatusContract,
    OperationStatusErrorContract,
} from '../data/contracts/common';
import { DelayDevBoxActionErrorCode, SkipDevBoxActionErrorCode } from '../data/contracts/dev-box-action';
import { AsyncState } from '../redux/store/common-state';
import { IsVoid } from '../types/is-void';
import { OptionalProperty } from '../types/optional-property';
import { UnionMap } from '../types/union-map';
import { unique } from '../utilities/array';
import { getMessageFromThrown } from '../utilities/error';
import { createInitializer } from '../utilities/initializer';
import { isString } from '../utilities/string';
import { SingleDevCenterClientFailureCode } from './single-dev-center';

/**
 * Constants
 */

type ClientFailureCode = 'MultipleFailures' | 'None' | 'NotFound' | SingleDevCenterClientFailureCode;

type RequestFailureCode = 'REQUEST_SEND_ERROR';

export type FailureCode =
    | ClientFailureCode
    | RequestFailureCode
    | CreateDevBoxErrorCode
    | CreateEnvironmentErrorCode
    | DelayDevBoxActionErrorCode
    | SkipDevBoxActionErrorCode;

export const FailureCode: UnionMap<FailureCode> = {
    MultipleFailures: 'MultipleFailures',
    None: 'None',
    NotFound: 'NotFound',
    REQUEST_SEND_ERROR: 'REQUEST_SEND_ERROR',
    ...SingleDevCenterClientFailureCode,
    ...CreateDevBoxErrorCode,
    ...CreateEnvironmentErrorCode,
    ...DelayDevBoxActionErrorCode,
    ...SkipDevBoxActionErrorCode,
};

export type FailureKind = 'ClientError' | 'FailureResponse';

export const FailureKind: UnionMap<FailureKind> = {
    ClientError: 'ClientError',
    FailureResponse: 'FailureResponse',
};

export type FailureOperation =
    | 'AddDevBox'
    | 'AddEnvironment'
    | 'AttemptSilentSingleSignOn'
    | 'BatchRequest'
    | 'CaptureSnapshot'
    | 'CheckAzureDevOpsOrganizationExists'
    | 'ClearFeatures'
    | 'CreateCustomizationGroup'
    | 'CreateOrReplaceEnvironment'
    | 'DataPlaneErrorParsing'
    | 'DelayAllDevBoxActions'
    | 'DelayDevBoxAction'
    | 'DeleteDevBox'
    | 'DeleteEnvironment'
    | 'DeployEnvironment'
    | 'DisableFeatures'
    | 'DiscoverAllControlPlaneResourcesInTenant'
    | 'DiscoverBackgroundResourcesForHome'
    | 'DiscoverDevBoxesInTenant'
    | 'DiscoverEnvironmentDefinitions'
    | 'DiscoverEnvironmentDefinitionsForEnvironments'
    | 'DiscoverEnvironmentDefinitionsForProjects'
    | 'DiscoverEnvironmentsInTenant'
    | 'DiscoverForegroundResourcesForHome'
    | 'DiscoverLocations'
    | 'DiscoverPermissionsForProjectEnvironmentTypes'
    | 'DiscoverPermissionsForProjects'
    | 'DiscoverPoolsForProjects'
    | 'DiscoverPoolsForDevBoxes'
    | 'DiscoverProjectEnvironmentTypes'
    | 'DiscoverProjectEnvironmentTypesAbilities'
    | 'DiscoverProjectEnvironmentTypesFromDataPlane'
    | 'DiscoverResourcesForDevBoxCreation'
    | 'DiscoverResourcesForEnvironmentCreation'
    | 'DiscoverSchedulesForDevBoxes'
    | 'DiscoverSchedulesForPools'
    | 'DismissMessage'
    | 'DismissQuickAction'
    | 'EnableFeatures'
    | 'ErrorBoundary'
    | 'FatalError'
    | 'GetAccessToken'
    | 'GetAzureDevOpsCommit'
    | 'GetAzureDevOpsPermissionEvaluation'
    | 'GetAzureDevOpsRepo'
    | 'GetAzureDevOpsRepoContents'
    | 'GetAzureDevOpsRepoForCloneUri'
    | 'GetAzureDevOpsRepoItem'
    | 'GetAzureDevOpsRepoUrlParts'
    | 'GetAzureDevOpsTree'
    | 'GetBannerLogoForOrganization'
    | 'GetCatalog'
    | 'GetCustomizationGroup'
    | 'GetCustomizationTaskDefinition'
    | 'GetCustomizationTaskLog'
    | 'GetDevBox'
    | 'GetDevBoxAction'
    | 'GetDevBoxOperation'
    | 'GetEnvironment'
    | 'GetEnvironmentAction'
    | 'GetEnvironmentDefinition'
    | 'GetEnvironmentOperation'
    | 'GetGraphDirectoryObject'
    | 'GetLongRunningOperation'
    | 'GetOrganization'
    | 'GetPermissions'
    | 'GetPhotoForSignedInUser'
    | 'GetPool'
    | 'GetProject'
    | 'GetProjectEnvironmentTypeAbilities'
    | 'GetProjectEnvironmentTypePermissions'
    | 'GetProjectPermissions'
    | 'GetRemoteConnection'
    | 'GetSchedule'
    | 'GetSchedules'
    | 'GetSignedInUser'
    | 'GetSnapshot'
    | 'GetStorageValue'
    | 'HibernateDevBox'
    | 'InitializeApplication'
    | 'InitializeAuthenticationState'
    | 'InitializeDevBoxesView'
    | 'InitializeFeatures'
    | 'InitializeHome'
    | 'InitializeHomeSubApplication'
    | 'InitializeIcons'
    | 'InitializeLocalization'
    | 'InitializeMocks'
    | 'InitializeOrganizationMetadata'
    | 'InitializeStorage'
    | 'InitializeUserMetadata'
    | 'IsInIframe'
    | 'ListAzureDevOpsBranches'
    | 'ListCatalogs'
    | 'ListCustomizationGroups'
    | 'ListCustomizationTaskDefinitions'
    | 'ListDevBoxActions'
    | 'ListDevBoxes'
    | 'ListDevBoxOperations'
    | 'ListSnapshots'
    | 'ListDevBoxRegionRecommendations'
    | 'ListEnvironmentActions'
    | 'ListEnvironmentDefinitionsByCatalog'
    | 'ListEnvironmentDefinitionsByProject'
    | 'ListEnvironmentOperations'
    | 'ListEnvironments'
    | 'ListLocations'
    | 'ListPools'
    | 'ListProjects'
    | 'ListProjectEnvironmentTypes'
    | 'ListProjectNames'
    | 'ListProjectsByDevCenter'
    | 'ListProjectEnvironmentTypesFromDataPlane'
    | 'ListResourcesInTenant'
    | 'ListSchedulesByProject'
    | 'ListTenants'
    | 'LoadAddDevBoxFormContent'
    | 'LoadAddEnvironmentFormContent'
    | 'LoadAzureDevOpsRepoResources'
    | 'LoadBackgroundResourcesForHome'
    | 'LoadControlPlaneResourcesForHome'
    | 'LoadCustomizationGroups'
    | 'LoadDevBoxActionsForDevBoxes'
    | 'LoadDevBoxCardContent'
    | 'LoadSecondaryDevBoxCardContent'
    | 'LoadSnapshots'
    | 'LoadDevBoxOperationsForDevBoxes'
    | 'LoadEnvironmentCardContent'
    | 'LoadPoolsUsedByDevBoxes'
    | 'LoadRemoteConnectionsForDevBoxes'
    | 'LoadResourcesForHome'
    | 'LoadSchedulesUsedByDevBoxes'
    | 'MultipleFailures'
    | 'ParseAzureDevOpsRepoItemId'
    | 'PollLongRunningOperation'
    | 'PollNonTerminalCustomizationGroup'
    | 'PollNonTerminalDevBox'
    | 'PollNonTerminalDevBoxesFromTenant'
    | 'PollNonTerminalEnvironment'
    | 'RepairDevBox'
    | 'RestartDevBox'
    | 'RestoreSnapshot'
    | 'ResumeDevBox'
    | 'ScrubPiiActionLogger'
    | 'SelectTenant'
    | 'SetFeatures'
    | 'SetStorageValue'
    | 'SetWelcomeTourStatus'
    | 'StoreUserSettings'
    | 'ShutdownDevBox'
    | 'SignIn'
    | 'SignOut'
    | 'SkipAllDevBoxActions'
    | 'SkipDevBoxAction'
    | 'SkipEnvironmentAction'
    | 'StartDevBox'
    | 'StopDevBox'
    | 'SyncDismissedHomeQuickActions'
    | 'SyncDismissedMessages'
    | 'SyncUserSettings'
    | 'SyncWelcomeTourStatus'
    | 'ToggleFeatures'
    | 'TryExpireSignIn'
    | 'UnhandledSagaError'
    | 'Unknown'
    | 'UpdateEnvironment'
    | 'ValidateCustomizationTasks'
    | 'WarmAllDevCenterNameRecords'
    | 'WarmDevCenterNameRecord'
    | 'LoadProjectsForSingleDevCenter'
    | 'LoadResourcesForSingleDevCenter'
    | 'LoadDevBoxesForSingleDevCenter'
    | 'InitializeSingleDevCenterUri'
    | 'SetSingleDevCenterUri'
    | 'GetRDGatewayHealth'
    | 'GetClosestRDGatewayRegion'
    | 'LoadDevBoxRegionRecommendations';

export const FailureOperation: UnionMap<FailureOperation> = {
    AddDevBox: 'AddDevBox',
    AddEnvironment: 'AddEnvironment',
    AttemptSilentSingleSignOn: 'AttemptSilentSingleSignOn',
    BatchRequest: 'BatchRequest',
    CaptureSnapshot: 'CaptureSnapshot',
    CheckAzureDevOpsOrganizationExists: 'CheckAzureDevOpsOrganizationExists',
    ClearFeatures: 'ClearFeatures',
    CreateCustomizationGroup: 'CreateCustomizationGroup',
    CreateOrReplaceEnvironment: 'CreateOrReplaceEnvironment',
    DataPlaneErrorParsing: 'DataPlaneErrorParsing',
    DelayAllDevBoxActions: 'DelayAllDevBoxActions',
    DelayDevBoxAction: 'DelayDevBoxAction',
    DeleteDevBox: 'DeleteDevBox',
    DeleteEnvironment: 'DeleteEnvironment',
    DeployEnvironment: 'DeployEnvironment',
    DisableFeatures: 'DisableFeatures',
    DiscoverAllControlPlaneResourcesInTenant: 'DiscoverAllControlPlaneResourcesInTenant',
    DiscoverBackgroundResourcesForHome: 'DiscoverBackgroundResourcesForHome',
    DiscoverDevBoxesInTenant: 'DiscoverDevBoxesInTenant',
    DiscoverEnvironmentDefinitions: 'DiscoverEnvironmentDefinitions',
    DiscoverEnvironmentDefinitionsForEnvironments: 'DiscoverEnvironmentDefinitionsForEnvironments',
    DiscoverEnvironmentDefinitionsForProjects: 'DiscoverEnvironmentDefinitionsForProjects',
    DiscoverEnvironmentsInTenant: 'DiscoverEnvironmentsInTenant',
    DiscoverForegroundResourcesForHome: 'DiscoverForegroundResourcesForHome',
    DiscoverLocations: 'DiscoverLocations',
    DiscoverPermissionsForProjectEnvironmentTypes: 'DiscoverPermissionsForProjectEnvironmentTypes',
    DiscoverPermissionsForProjects: 'DiscoverPermissionsForProjects',
    DiscoverPoolsForProjects: 'DiscoverPoolsForProjects',
    DiscoverPoolsForDevBoxes: 'DiscoverPoolsForDevBoxes',
    DiscoverProjectEnvironmentTypes: 'DiscoverProjectEnvironmentTypes',
    DiscoverProjectEnvironmentTypesAbilities: 'DiscoverProjectEnvironmentTypesAbilities',
    DiscoverProjectEnvironmentTypesFromDataPlane: 'DiscoverProjectEnvironmentTypesFromDataPlane',
    DiscoverResourcesForDevBoxCreation: 'DiscoverResourcesForDevBoxCreation',
    DiscoverResourcesForEnvironmentCreation: 'DiscoverResourcesForEnvironmentCreation',
    DiscoverSchedulesForDevBoxes: 'DiscoverSchedulesForDevBoxes',
    DiscoverSchedulesForPools: 'DiscoverSchedulesForPools',
    DismissMessage: 'DismissMessage',
    DismissQuickAction: 'DismissQuickAction',
    EnableFeatures: 'EnableFeatures',
    ErrorBoundary: 'ErrorBoundary',
    FatalError: 'FatalError',
    GetAccessToken: 'GetAccessToken',
    GetAzureDevOpsCommit: 'GetAzureDevOpsCommit',
    GetAzureDevOpsPermissionEvaluation: 'GetAzureDevOpsPermissionEvaluation',
    GetAzureDevOpsRepo: 'GetAzureDevOpsRepo',
    GetAzureDevOpsRepoContents: 'GetAzureDevOpsRepoContents',
    GetAzureDevOpsRepoForCloneUri: 'GetAzureDevOpsRepoForCloneUri',
    GetAzureDevOpsRepoItem: 'GetAzureDevOpsRepoItem',
    GetAzureDevOpsRepoUrlParts: 'GetAzureDevOpsRepoUrlParts',
    GetAzureDevOpsTree: 'GetAzureDevOpsTree',
    GetBannerLogoForOrganization: 'GetBannerLogoForOrganization',
    GetCatalog: 'GetCatalog',
    GetCustomizationGroup: 'GetCustomizationGroup',
    GetCustomizationTaskDefinition: 'GetCustomizationTaskDefinition',
    GetCustomizationTaskLog: 'GetCustomizationTaskLog',
    GetDevBox: 'GetDevBox',
    GetDevBoxAction: 'GetDevBoxAction',
    GetDevBoxOperation: 'GetDevBoxOperation',
    GetEnvironment: 'GetEnvironment',
    GetEnvironmentAction: 'GetEnvironmentAction',
    GetEnvironmentDefinition: 'GetEnvironmentDefinition',
    GetEnvironmentOperation: 'GetEnvironmentOperation',
    GetGraphDirectoryObject: 'GetGraphDirectoryObject',
    GetLongRunningOperation: 'GetLongRunningOperation',
    GetOrganization: 'GetOrganization',
    GetPermissions: 'GetPermissions',
    GetPhotoForSignedInUser: 'GetPhotoForSignedInUser',
    GetPool: 'GetPool',
    GetProject: 'GetProject',
    GetProjectEnvironmentTypeAbilities: 'GetProjectEnvironmentTypeAbilities',
    GetProjectEnvironmentTypePermissions: 'GetProjectEnvironmentTypePermissions',
    GetProjectPermissions: 'GetProjectPermissions',
    GetRemoteConnection: 'GetRemoteConnection',
    GetSchedule: 'GetSchedule',
    GetSchedules: 'GetSchedules',
    GetSignedInUser: 'GetSignedInUser',
    GetSnapshot: 'GetSnapshot',
    GetStorageValue: 'GetStorageValue',
    HibernateDevBox: 'HibernateDevBox',
    InitializeApplication: 'InitializeApplication',
    InitializeAuthenticationState: 'InitializeAuthenticationState',
    InitializeDevBoxesView: 'InitializeDevBoxesView',
    InitializeFeatures: 'InitializeFeatures',
    InitializeHome: 'InitializeHome',
    InitializeHomeSubApplication: 'InitializeHomeSubApplication',
    InitializeIcons: 'InitializeIcons',
    InitializeLocalization: 'InitializeLocalization',
    InitializeMocks: 'InitializeMocks',
    InitializeOrganizationMetadata: 'InitializeOrganizationMetadata',
    InitializeStorage: 'InitializeStorage',
    InitializeUserMetadata: 'InitializeUserMetadata',
    IsInIframe: 'IsInIframe',
    ListAzureDevOpsBranches: 'ListAzureDevOpsBranches',
    ListCatalogs: 'ListCatalogs',
    ListCustomizationGroups: 'ListCustomizationGroups',
    ListCustomizationTaskDefinitions: 'ListCustomizationTaskDefinitions',
    ListDevBoxActions: 'ListDevBoxActions',
    ListDevBoxes: 'ListDevBoxes',
    ListDevBoxOperations: 'ListDevBoxOperations',
    ListDevBoxRegionRecommendations: 'ListDevBoxRegionRecommendations',
    ListEnvironmentActions: 'ListEnvironmentActions',
    ListEnvironmentDefinitionsByCatalog: 'ListEnvironmentDefinitionsByCatalog',
    ListEnvironmentDefinitionsByProject: 'ListEnvironmentDefinitionsByProject',
    ListEnvironmentOperations: 'ListEnvironmentOperations',
    ListEnvironments: 'ListEnvironments',
    ListLocations: 'ListLocations',
    ListPools: 'ListPools',
    ListProjects: 'ListProjects',
    ListProjectEnvironmentTypes: 'ListProjectEnvironmentTypes',
    ListProjectEnvironmentTypesFromDataPlane: 'ListProjectEnvironmentTypesFromDataPlane',
    ListProjectNames: 'ListProjectNames',
    ListProjectsByDevCenter: 'ListProjectsByDevCenter',
    ListResourcesInTenant: 'ListResourcesInTenant',
    ListSchedulesByProject: 'ListSchedulesByProject',
    ListSnapshots: 'ListSnapshots',
    ListTenants: 'ListTenants',
    LoadAddDevBoxFormContent: 'LoadAddDevBoxFormContent',
    LoadAddEnvironmentFormContent: 'LoadAddEnvironmentFormContent',
    LoadAzureDevOpsRepoResources: 'LoadAzureDevOpsRepoResources',
    LoadBackgroundResourcesForHome: 'LoadBackgroundResourcesForHome',
    LoadControlPlaneResourcesForHome: 'LoadControlPlaneResourcesForHome',
    LoadCustomizationGroups: 'LoadCustomizationGroups',
    LoadDevBoxActionsForDevBoxes: 'LoadDevBoxActionsForDevBoxes',
    LoadDevBoxCardContent: 'LoadDevBoxCardContent',
    LoadDevBoxOperationsForDevBoxes: 'LoadDevBoxOperationsForDevBoxes',
    LoadEnvironmentCardContent: 'LoadEnvironmentCardContent',
    LoadPoolsUsedByDevBoxes: 'LoadPoolsUsedByDevBoxes',
    LoadRemoteConnectionsForDevBoxes: 'LoadRemoteConnectionsForDevBoxes',
    LoadResourcesForHome: 'LoadResourcesForHome',
    LoadSchedulesUsedByDevBoxes: 'LoadSchedulesUsedByDevBoxes',
    LoadSecondaryDevBoxCardContent: 'LoadSecondaryDevBoxCardContent',
    LoadSnapshots: 'LoadSnapshots',
    MultipleFailures: 'MultipleFailures',
    ParseAzureDevOpsRepoItemId: 'ParseAzureDevOpsRepoItemId',
    PollLongRunningOperation: 'PollLongRunningOperation',
    PollNonTerminalCustomizationGroup: 'PollNonTerminalCustomizationGroup',
    PollNonTerminalDevBox: 'PollNonTerminalDevBox',
    PollNonTerminalDevBoxesFromTenant: 'PollNonTerminalDevBoxesFromTenant',
    PollNonTerminalEnvironment: 'PollNonTerminalEnvironment',
    RepairDevBox: 'RepairDevBox',
    RestartDevBox: 'RestartDevBox',
    RestoreSnapshot: 'RestoreSnapshot',
    ResumeDevBox: 'ResumeDevBox',
    ScrubPiiActionLogger: 'ScrubPiiActionLogger',
    SelectTenant: 'SelectTenant',
    SetFeatures: 'SetFeatures',
    SetStorageValue: 'SetStorageValue',
    StoreUserSettings: 'StoreUserSettings',
    SetWelcomeTourStatus: 'SetWelcomeTourStatus',
    ShutdownDevBox: 'ShutdownDevBox',
    SignIn: 'SignIn',
    SignOut: 'SignOut',
    SkipAllDevBoxActions: 'SkipAllDevBoxActions',
    SkipDevBoxAction: 'SkipDevBoxAction',
    SkipEnvironmentAction: 'SkipEnvironmentAction',
    StartDevBox: 'StartDevBox',
    StopDevBox: 'StopDevBox',
    SyncDismissedHomeQuickActions: 'SyncDismissedHomeQuickActions',
    SyncDismissedMessages: 'SyncDismissedMessages',
    SyncUserSettings: 'SyncUserSettings',
    SyncWelcomeTourStatus: 'SyncWelcomeTourStatus',
    ToggleFeatures: 'ToggleFeatures',
    TryExpireSignIn: 'TryExpireSignIn',
    UnhandledSagaError: 'UnhandledSagaError',
    Unknown: 'Unknown',
    UpdateEnvironment: 'UpdateEnvironment',
    ValidateCustomizationTasks: 'ValidateCustomizationTasks',
    WarmAllDevCenterNameRecords: 'WarmAllDevCenterNameRecords',
    WarmDevCenterNameRecord: 'WarmDevCenterNameRecord',
    LoadProjectsForSingleDevCenter: 'LoadProjectsForSingleDevCenter',
    LoadResourcesForSingleDevCenter: 'LoadResourcesForSingleDevCenter',
    LoadDevBoxesForSingleDevCenter: 'LoadDevBoxesForSingleDevCenter',
    InitializeSingleDevCenterUri: 'InitializeSingleDevCenterUri',
    SetSingleDevCenterUri: 'SetSingleDevCenterUri',
    GetRDGatewayHealth: 'GetRDGatewayHealth',
    GetClosestRDGatewayRegion: 'GetClosestRDGatewayRegion',
    LoadDevBoxRegionRecommendations: 'LoadDevBoxRegionRecommendations',
};

export type InitializationState = 'NotStarted' | 'Initializing' | 'Success' | 'Failed' | 'Error';

export const InitializationState: UnionMap<InitializationState> = {
    NotStarted: 'NotStarted',
    Initializing: 'Initializing',
    Success: 'Success',
    Failed: 'Failed',
    Error: 'Error',
};

export type Nothing = 'Nothing';

export const Nothing: Nothing = 'Nothing';

/**
 * Application models
 */

export interface AggregatedFailure {
    codes: string[];
    failures: FailureResponse[];
    outcome: 'Failed';
}

export interface AggregatedPartialSuccess {
    codes: string[];
    failures: FailureResponse[];
    outcome: 'PartialSuccess';
}

export interface AggregatedSuccess {
    outcome: 'Success';
}

export type AggregatedResult = AggregatedFailure | AggregatedPartialSuccess | AggregatedSuccess;

export class ClientError extends Error implements ClientErrorLike {
    public code: string;
    public details?: Failure[];
    public innerStack?: string;
    public kind: 'ClientError';
    public operation: FailureOperation;

    constructor(
        error: unknown,
        operation: FailureOperation = FailureOperation.Unknown,
        code: string = FailureCode.None,
        details?: Failure[]
    ) {
        super(getMessageFromThrown(error));

        this.code = code;
        this.kind = 'ClientError';
        this.name = operation;
        this.operation = operation;

        if (hasClientErrorLikeCode(error) && code === FailureCode.None && error.code !== FailureCode.None) {
            this.code = error.code;
        }

        if (hasClientErrorLikeDetails(error)) {
            this.details = error.details;
        }

        // This is on purpose: if the original error had a recognized operation, overwrite ours with theirs
        if (hasClientErrorLikeOperation(error) && error.operation !== FailureOperation.Unknown) {
            this.name = error.operation;
            this.operation = error.operation;
        }

        if (hasClientErrorLikeStack(error)) {
            this.innerStack = error.stack;
        }

        if (details) {
            this.details = details;
        }
    }
}

export type ClientErrorDetails = ClientErrorDetailProperties[];
export type ClientErrorDetailProperties = OptionalProperty<ClientErrorProperties, 'name'>;

export interface ClientErrorProperties
    extends Pick<ClientErrorLike, 'message'>,
        Pick<ClientErrorLike, 'code'>,
        Pick<ClientErrorLike, 'stack'> {
    details: ClientErrorDetails;
    name: string;
    originalStack?: string;
}

export interface ClientErrorLike extends Failure<'ClientError'> {
    innerStack?: string;
    name: string;
    stack?: string;
}

export type CloudError = CloudErrorContract;
export type CloudErrorBody = CloudErrorBodyContract;

export type DataResponse<TData = void> = FailureResponse | SuccessResponse<TData>;

export interface Entity<TData = unknown> {
    data: TData;
    id: string;
}

export interface Failure<TKind extends FailureKind = FailureKind> {
    code: string;
    details?: Failure[];
    kind: TKind;
    message: string;
    operation: FailureOperation;
}

export interface FailureResponse extends Failure<'FailureResponse'> {
    headers?: Headers;
    statusCode?: number;
    succeeded: false;
}

export interface InitializationStatus {
    failure?: Failure;
    state: InitializationState;
}

export interface Status {
    failure?: Failure;
    state: AsyncState;
}

export type SuccessResponse<TData = void> = IsVoid<TData, { succeeded: true }, { data: TData; succeeded: true }>;

export type OperationStatus = OperationStatusContract;

export type OperationStatusError = OperationStatusErrorContract;

/**
 * Initializers
 */

export const AggregatedFailure = (...failures: FailureResponse[]): AggregatedFailure => ({
    codes: unique(failures.flatMap((failure) => failure.code)),
    failures,
    outcome: 'Failed',
});

export const AggregatedPartialSuccess = (...failures: FailureResponse[]): AggregatedPartialSuccess => ({
    codes: unique(failures.flatMap((failure) => failure.code)),
    failures,
    outcome: 'PartialSuccess',
});

export const AggregatedResult = (
    response: DataResponse,
    failureResponsePredicate: <TData>(response: DataResponse<TData>) => response is FailureResponse = isFailureResponse
): AggregatedResult => (failureResponsePredicate(response) ? AggregatedFailure(response) : AggregatedSuccess());

export const AggregatedSuccess = (): AggregatedSuccess => ({ outcome: 'Success' });

export const ClientErrorLike = createInitializer<ClientErrorLike>({
    code: FailureCode.None,
    kind: 'ClientError',
    message: '',
    name: FailureOperation.Unknown,
    operation: FailureOperation.Unknown,
});

export const Entity = <TData = unknown>(id: string, data: TData, isIdCaseSensitive = false): Entity<TData> => ({
    data,
    id: isIdCaseSensitive ? id : id.toLowerCase(),
});

export const FailureResponse = createInitializer<FailureResponse>({
    code: FailureCode.None,
    kind: 'FailureResponse',
    message: '',
    operation: FailureOperation.Unknown,
    succeeded: false,
});

export const InitializationStatus = createInitializer<InitializationStatus>({ state: InitializationState.NotStarted });

export const Status = createInitializer<Status>({ state: AsyncState.NotStarted });

/**
 * Type guards
 */

export const hasClientErrorLikeCode = (error: unknown): error is Pick<ClientErrorLike, 'code'> => {
    const errorWithCode = error as Pick<ClientErrorLike, 'code'>;
    return errorWithCode !== undefined && isString(errorWithCode.code);
};

export const hasClientErrorLikeDetails = (error: unknown): error is Pick<ClientErrorLike, 'details'> => {
    const errorWithDetails = error as Pick<ClientErrorLike, 'details'>;

    return (
        errorWithDetails !== undefined &&
        Array.isArray(errorWithDetails.details) &&
        errorWithDetails.details.every(isFailure)
    );
};

export const hasClientErrorLikeMessage = (error: unknown): error is Pick<ClientErrorLike, 'message'> => {
    const errorWithMessage = error as Pick<ClientErrorLike, 'message'>;
    return errorWithMessage !== undefined && isString(errorWithMessage.message);
};

export const hasClientErrorLikeOperation = (error: unknown): error is Pick<ClientErrorLike, 'operation'> => {
    const errorWithOperation = error as Pick<ClientErrorLike, 'operation'>;
    return errorWithOperation !== undefined && isFailureOperation(errorWithOperation.operation);
};

export const hasClientErrorLikeStack = (error: unknown): error is Pick<ClientErrorLike, 'stack'> => {
    const errorWithStack = error as Pick<ClientErrorLike, 'stack'>;
    return errorWithStack !== undefined && isString(errorWithStack.stack);
};

export const isAggregatedFailure = (response: AggregatedResult): response is AggregatedFailure =>
    response.outcome === 'Failed';

export const isAggregatedPartialSuccess = (response: AggregatedResult): response is AggregatedPartialSuccess =>
    response.outcome === 'PartialSuccess';

export const isAggregatedSuccess = (response: AggregatedResult): response is AggregatedSuccess =>
    response.outcome === 'Success';

export const isClientError = (error: unknown): error is ClientError => {
    return error !== undefined && error instanceof ClientError;
};

export const isClientErrorDetailProperties = (error: unknown): error is ClientErrorDetailProperties => {
    if (!error) {
        return false;
    }

    const errorProps = error as ClientErrorDetailProperties;
    return (
        (errorProps.code === undefined || isString(errorProps.code)) &&
        isString(errorProps.message) &&
        (errorProps.name === undefined || isString(errorProps.name)) &&
        (errorProps.originalStack === undefined || isString(errorProps.originalStack)) &&
        (errorProps.stack === undefined || isString(errorProps.stack)) &&
        (errorProps.details === undefined || isString(errorProps.details))
    );
};

export const isClientErrorLike = (error: unknown): error is ClientErrorLike => {
    if (!error) {
        return false;
    }

    if (!isFailure(error)) {
        return false;
    }

    const { innerStack, stack } = error as ClientErrorLike;

    return (innerStack === undefined || isString(innerStack)) && (stack === undefined || isString(stack));
};

export const isCloudErrorBody = (value: unknown): value is CloudErrorBody => {
    if (!value) {
        return false;
    }

    const { code, details, message } = value as CloudErrorBody;

    return isString(code) && (details === undefined || Array.isArray(details)) && isString(message);
};

export const isDataResponse = <TData = undefined>(value: unknown): value is DataResponse<TData> =>
    typeof (value as DataResponse<TData>).succeeded === 'boolean' &&
    (isFailureResponse(value as DataResponse<TData>) || isSuccessResponse(value as DataResponse<TData>));

export const isFailure = (value: unknown): value is Failure => {
    if (!value) {
        return false;
    }

    const { code, details, kind, message, operation } = value as Failure;

    return (
        isString(code) &&
        (details === undefined || Array.isArray(details)) &&
        isFailureKind(kind) &&
        isString(message) &&
        isFailureOperation(operation)
    );
};

export const isFailureKind = (value: string): value is FailureKind =>
    Object.values(FailureKind).includes(value as FailureKind);

export const isFailureOperation = (value: string): value is FailureOperation =>
    Object.values(FailureOperation).includes(value as FailureOperation);

export const isFailureResponse = <TData = undefined>(response: DataResponse<TData>): response is FailureResponse =>
    !response.succeeded;

export const isNothing = (value: unknown): value is Nothing => value === Nothing;

export const isSuccessResponse = <TData = undefined>(
    response: DataResponse<TData>
): response is SuccessResponse<TData> => response.succeeded;
